import { BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { AppModule } from './app.module';
import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay';
import { SharedModule } from './shared/shared.module';
import { NzSelectModule } from 'ng-zorro-antd/select';

registerLocaleData(en);

@NgModule({
  imports: [
    AppRoutingModule,
    NgZorroAntdModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    AppModule,
    // OverlayModule
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }, CdkConnectedOverlay],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
