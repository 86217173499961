import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd';

import { ContentfulService } from '../../services/contentful.service';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-get-mail',
  templateUrl: './get-mail.component.html',
  styleUrls: ['./get-mail.component.scss'],
})
export class GetMailComponent implements OnInit {
  emailControl = new FormControl(null, [Validators.required, Validators.email]);
  sendingMail: boolean;

  data: any;

  constructor(
    private contentFul: ContentfulService,
    private route: ActivatedRoute,
    private appService: AppService,
    private message: NzMessageService
  ) {}

  ngOnInit() {}

  clearInput() {
    this.emailControl.reset();
  }

  submit() {
    const phoneNumber = this.data ? this.data.phoneNumber : null;

    this.sendingMail = true;
    this.contentFul
      .saveEmail(
        this.emailControl.value,
        this.route.root.firstChild.snapshot.url.toString(),
        phoneNumber
      )
      .then((data) => {
        this.sendingMail = false;
        this.message.success(
          'Keep an eye on your inbox, we will be in touch as soon as we launch'
        );
        this.appService.closeOverlay();
      })
      .catch((error) => {
        this.sendingMail = false;
        this.message.error(
          "Oops!!! Seems like you've already registered your email, keep an eye on your inbox"
        );
      });
  }
}
