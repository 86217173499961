import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'youtube-embed',
  templateUrl: './youtube-embed.component.html',
  styleUrls: ['./youtube-embed.component.scss'],
})
export class YoutubeEmbedComponent implements OnInit {
  @Input() data: any;
  url: SafeResourceUrl;

  @ViewChild('playerWrapper', { static: false }) playerWrapper: ElementRef<
    HTMLElement
  >;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.initYoutubePlayer(this.data.videoID);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

  initYoutubePlayer(videoId) {
    // reset youtube iframe api
    window['YT'] = null;

    let player;
    window['onYouTubeIframeAPIReady'] = () => {
      player = new window['YT'].Player('player', {
        height: '100%',
        width: '100%',
        videoId,
        playerVars: {
          origin: window.location.origin,
          rel: 0,
        },
        events: {
          onReady: (e) => e.target.playVideo(),
          onStateChange: (event) => {
            if (event.data === window['YT'].PlayerState.ENDED) {
              stopVideo();
            }
          },
        },
      });
    };

    function stopVideo() {
      const playerWrapper = document.querySelector(
        '.player-wrapper'
      ) as HTMLElement;
      playerWrapper.classList.add('thumbnail');

      player.stopVideo();
    }

    if (!window['YT']) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }

  playVideo() {
    this.playerWrapper.nativeElement.classList.remove('thumbnail');
  }
}
