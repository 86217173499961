import { Directive, ElementRef, Output, EventEmitter, Input, OnInit, NgZone } from '@angular/core';

@Directive({
  selector: '[appPlaceSearch]'
})
export class PlaceSearchDirective implements OnInit {

  @Input() searchConfig: google.maps.places.AutocompleteOptions;
  @Output() placeSelected = new EventEmitter();

  constructor(private el: ElementRef, private ngZone : NgZone) {
  }

  ngOnInit() {
    this.bindPlaceSearch();
  }

  private bindPlaceSearch() {
    const autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.searchConfig);
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(()=>{
        this.placeSelected.emit(autocomplete.getPlace());
      })
    });
  }

}