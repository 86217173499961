import { Pipe, PipeTransform } from '@angular/core';
import { toCamelCase } from '../helpers/utils.helper';

@Pipe({
  name: 'toCamelCase',
})
export class ToCamelCasePipe implements PipeTransform {
  transform(value: string, args: string[]): string {
    if (!value) {
      return value;
    }

    return toCamelCase(value);
  }
}
