import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import en from '@angular/common/locales/en';
import { FlexLayoutModule } from "@angular/flex-layout";
import { ModalModule } from 'ngx-bootstrap/modal'

import {  NZ_I18N, en_US, NgZorroAntdModule } from 'ng-zorro-antd';


/* Core modules */
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { SharedModule } from './shared/shared.module';

/* Components */
// import { HomeComponent } from './pages/home/home.component';
// import { RideComponent } from './pages/ride/ride.component';
// import { CitiesComponent } from './pages/cities/cities.component';
// import { ContactUsComponent } from './pages/contact-us/contact-us.component';
// import { RewardsComponent } from './pages/rewards/rewards.component';
// import { SafetyComponent } from './pages/safety/safety.component';
// import { PayComponent } from './pages/pay/pay.component';
// import { AutonomousTechnologyComponent } from './pages/autonomous-technology/autonomous-technology.component';
// import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
// import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
// import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
// import { ThankYouComponent } from './pages/thank-you/thank-you.component';
// import { MobileMoneyComponent } from './pages/mobile-money/mobile-money.component';
// import { MoneyTransferComponent } from './pages/money-transfer/money-transfer.component';
// import { MoneyTransferCarouselComponent } from './pages/money-transfer/money-transfer-carousel/money-transfer-carousel.component';
// import { MoneyTransferCalculatorComponent } from './pages/money-transfer/money-transfer-calculator/money-transfer-calculator.component';
// import { CardComponent } from './pages/card/card.component';
// import { PaymentComponent } from './pages/payment/payment.component';
// import { PaymentRedirectPassengerComponent } from './pages/redirects/payment-redirect-passenger.component';
// import { PaymentRedirectDriverComponent } from './pages/redirects/payment-redirect-driver.component';
// import { CheckoutComponent } from './pages/checkout/checkout.component';
// import { TypeformComponent } from './pages/typeform/typeform.component';
import { AppComponent } from './app.component';
import { LocationResolverService } from './resolvers/location-resolver.service';
import { CookieModule } from '@ngx-toolkit/cookie';
import { AgmCoreModule } from '@agm/core';
import { environment } from './../environments/environment';

registerLocaleData(en);

export function locationProviderFactory(provider: LocationResolverService) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    ComponentsModule,
    DirectivesModule,
    SharedModule,
    FlexLayoutModule,
    NgZorroAntdModule,
    ModalModule.forRoot(),
    CookieModule.forRoot(),
    AgmCoreModule.forRoot({
        apiKey: environment.GOOGLE_API_KEY,
        libraries: ['places'],
      }),     
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: APP_INITIALIZER,
      useFactory: locationProviderFactory,
      deps: [LocationResolverService],
      multi: true,
    },
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
