import { Injectable } from '@angular/core';

import * as contentful from 'contentful';
import * as contentfulManager from 'contentful-management';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  CONTENTFUL_SPACE_ID = 'rux5p7gfeb44';
  CONTENTFUL_ACCESS_TOKEN = 'keu_bXea7AKTdqWo71AE98HC8YQY4p38At81IU_NQYY';
  content_management_api_key =
    'CFPAT-JTdcJ3dK9jNwXiR8zf0uBwk8-oMvFY8WQEAOr_x7eYw';

  constructor() {}
  get client() {
    return contentful.createClient({
      // This is the space ID. A space is like a project folder in Contentful terms
      space: this.CONTENTFUL_SPACE_ID,
      // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
      accessToken: this.CONTENTFUL_ACCESS_TOKEN,
    });
  }

  get manager() {
    return contentfulManager.createClient({
      accessToken: this.content_management_api_key,
    });
  }

  saveEmail(email, source, phoneNumber: string = null) {
    return this.manager
      .getSpace(this.CONTENTFUL_SPACE_ID)
      .then((space) =>
        space.createEntry('waitlist', {
          fields: {
            email: {
              'en-US': email,
            },
            source: {
              'en-US': source,
            },
            phoneNumber: {
              'en-US': phoneNumber,
            },
          },
        })
      )
      .then((spaceEntry) => {
        return this.manager
          .getSpace(this.CONTENTFUL_SPACE_ID)
          .then((space) => space.getEntry(spaceEntry.sys.id))
          .then((entry) => entry.publish());
      });
  }

  // Load all Content Types in your space from Contentful
  fetchContentTypes() {
    return this.client.getContentTypes().then((response) => response.items);
  }

  // Load all entries for a given Content Type from Contentful
  fetchEntriesForContentType(contentType, limit = null) {
    const options = { content_type: contentType.sys.id, include: 2 };

    if (!!limit) {
      options['limit'] = limit;
    }
    return this.client.getEntries(options);
  }

  fetchEntries(options) {
    return this.client.getEntries(options);
  }

  fetchEntryBySlug(contentType, slug) {
    return this.client
      .getEntries({
        content_type: contentType.sys.id,
        'fields.slug': slug,
      })
      .then((entries) => entries.items[0]);
  }

  getIncludes(data, includes) {
    return includes[data.sys.LinkType].find((include) => {
      return include.sys.id === data.sys.id;
    });
  }

  transformData(data) {
    return data.items.map((item) => {
      return (item['display'] = item.fields);
    });
  }
}
