import { Component, OnInit, Input } from '@angular/core';

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FAQSComponent implements OnInit {
  @Input() headerTitle = 'FAQs';
  @Input() faqs: any[] = [];

  constructor() {}

  ngOnInit() {}

  stringifyContent(content) {
    return documentToHtmlString(content);
  }
}
