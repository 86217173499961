import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ViewChild,
} from '@angular/core';
import { HostListener, Inject } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from '../../services/app.service';

import { trigger, style, animate, transition } from '@angular/animations';

import { SignUpComponent } from '../sign-up/sign-up.component';
import { ComponentHostDirective } from '../../directives/component-host/component-host.directive';
import { SignInComponent } from '../sign-in/sign-in.component';
import { LocationService } from '../../services/location.service';
import { LocationSearchComponent } from '../location-search/location-search.component';
import { BlogService } from '../../services/blog.service';
import { MobileMenuComponent } from '../mobile-menu/mobile-menu.component';
import { LINKS } from './mobile-header-links';
import { environment } from '../../../environments/environment';
import { SlidesService } from '../../services/slides.service';
import { AuthService } from '../../services/auth.service';
import { HelpService } from '../../services/help.service';
import { ContentfulService } from '../../services/contentful.service';
import { ContentService } from '../../services/content.service';
import { GetMailComponent } from '../get-mail/get-mail.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('overlayTrigger', [
      transition(':enter', [
        style({ height: '0px' }),
        animate('.3s', style({ height: 'calc(100vh - 80px)' })),
      ]),
      transition(':leave', [
        style({ height: 'calc(100vh - 80px)' }),
        animate('.3s ease-in', style({ height: '0px' })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  transparent = false;
  mobileMenu = false;
  showOverlay = false;
  lastScrollPosition = { left: 0, top: 0 };

  @ViewChild(ComponentHostDirective, { static: false })
  componentHost: ComponentHostDirective;
  currentHeaderType = 'default';
  categories = [];
  activeSlide: any;

  products: any[] = [];

  showMegaMenu: boolean;
  activeMenu = true;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appService: AppService,
    private locationService: LocationService,
    private blogService: BlogService,
    private helpService: HelpService,
    private route: ActivatedRoute,
    private slidesService: SlidesService,
    private authService: AuthService,
    private contentfulService: ContentfulService,
    private contentService: ContentService
  ) {
    this.changeHeaderBehavior();
    this.appService.overlayBus.subscribe((result: any) => {
      const { action, component, data } = result;
      if (action === 'OPEN') {
        // console.log(`====>`,component.name)
        this.saveWindowScrollPosition(component.name);
        this.scrollToPosition({ left: 0, top: 0 });
        this.showOverlay = true;
        this.transparent = false;
        this.window.setTimeout(() => {
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            component
          );
          const viewContainerRef = this.componentHost.viewContainerRef;
          viewContainerRef.clear();
          const componentRef = viewContainerRef.createComponent(
            componentFactory
          );
          componentRef.instance['links'] = LINKS.default;
          if (data) {
            componentRef.instance['data'] = data;
          }
          this.hidePageContent();
        }, 400);
      } else {
        this.setTransparency();
        this.showPageContent();
        this.showOverlay = false;
        this.scrollToPosition(this.lastScrollPosition);
      }
    });
  }

  currentHeaderBehaviour = 'default';

  ngOnInit() {
    this.router.events.subscribe(() => {
      if (this.mobileMenu) {
        this.mobileMenuToggle();
      }
    });

    // set active slide
    this.slidesService.sharedSlide.subscribe(
      (slide) => (this.activeSlide = slide)
    );

    // fetch products
    this.getProducts();
  }

  toggleMegaMenu2(invalidLink = false) {
    this.activeMenu = false;
    setTimeout(() => {
      this.activeMenu = true;
      if (invalidLink) {
        this.openMailOverlay();
      }
    }, 500);
  }

  toggleMegaMenu(product: any, invalidLink = false) {
    let url = `/tr1pp-portal${product.fields.urlLinkPath}`;
    if (product.fields.urlLinkPath !== '' && invalidLink === false) {
      this.router.navigate([url]);
    }
    this.activeMenu = false;
    setTimeout(() => {
      this.activeMenu = true;
      if (invalidLink) {
        this.openMailOverlay();
      }
    }, 500);
  }

  getProducts() {
    this.contentfulService
      .fetchEntries({
        content_type: 'regionProducts',
      })
      .then((data) => {
        const content = this.contentService.getContent({ content: data });
        this.products = content.products;
      });
  }

  saveWindowScrollPosition(componentName) {
    this.lastScrollPosition.left = this.window.scrollX;
    this.lastScrollPosition.top = this.window.scrollY;
  }

  scrollToPosition(position) {
    this.window.scrollTo({ ...position, behavior: 'auto' });
  }

  changeHeaderBehavior() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .forEach((e) => {
        // this.currentHeaderBehaviour = this.route.root.firstChild.snapshot.data[
        //   'headerBehaviour'
        // ];
        // this.currentHeaderType = this.route.root.firstChild.snapshot.data[
        //   'headerType'
        // ];
        this.currentHeaderBehaviour = this.route.snapshot.firstChild.data['headerBehaviour'];
        this.currentHeaderType = this.route.snapshot.firstChild.data['headerType'];
        if (this.currentHeaderType === 'blog') {
          this.getBlogCategories();
        }
        this.setTransparency();
        this.showOverlay = false;
        this.showPageContent();
      });
  }

  get authUser() {
    return this.authService.authUser;
  }

  get isAuthenticated() {
    return this.authService.isAuth;
  }

  setTransparency() {
    if (this.currentHeaderBehaviour !== 'default') {
      this.transparent = false;
    } else {
      this.transparent = true;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.showOverlay) {
      if (this.currentHeaderBehaviour === 'default') {
        const number =
          this.window.pageYOffset ||
          this.document.documentElement.scrollTop ||
          this.document.body.scrollTop ||
          0;
        if (number >= 100) {
          this.transparent = false;
        } else {
          this.transparent = true;
        }
      }
    }
  }

  mobileMenuToggle() {
    // this.mobileMenu = !this.mobileMenu;
    if (this.showOverlay) {
      this.appService.closeOverlay();
    } else {
      this.appService.openOverlay(MobileMenuComponent);
    }
  }

  onSignUpClick() {
    this.appService.openOverlay(SignUpComponent);
  }

  onSignInClick() {
    this.appService.openOverlay(SignInComponent);
  }

  closeOverlay() {
    this.appService.closeOverlay();
  }

  get currentLocation() {
    return this.locationService.currentLocation;
  }

  openLocationOverlay() {
    this.appService.openOverlay(LocationSearchComponent);
  }

  hidePageContent() {
    this.document.body.style.overflowY = 'scroll';
    this.document
      .querySelectorAll('app-tr1pp-portal > *:not(app-header)')
      .forEach((el) => el.classList.add('no-display'));
  }

  showPageContent() {
    this.document.body.style.overflowY = 'initial';
    this.document
      .querySelectorAll('app-tr1pp-portal > *:not(app-header)')
      .forEach((el) => el.classList.remove('no-display'));
  }

  getBlogCategories() {
    this.blogService.getCategories().then((res: any) => {
      this.categories = res.items.map((item: any) => item.fields);
    });
  }

  get accountURI() {
    let url;

    if (['driverPartner', 'partner'].includes(this.authService.authType)) {
      url = environment.driverPortalUrl;
    } else {
      url = environment.businessPortalUrl.concat('/dashboard/support-messages');
    }

    return url;
  }

  openMailOverlay() {
    this.appService.openOverlay(GetMailComponent);
  }

  logout() {
    this.authService.logout();
    this.window.location.reload();
  }
}
