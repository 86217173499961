import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { retry } from 'rxjs/operators';

import { CookieService } from '@ngx-toolkit/cookie';
import { ContentfulService } from './contentful.service';

export interface LocationData {
  country: CountryData;
  city: CityData;
}

export interface CountryData {
  name: string;
  iso2: string;
}

export interface CityData {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private contentfulService: ContentfulService
  ) {}

  setLocation(location: LocationData) {
    this.cookieService.setItem('client_location', JSON.stringify(location));
  }

  get currentLocation(): LocationData {
    if (this.cookieService.hasItem('client_location')) {
      return JSON.parse(this.cookieService.getItem('client_location'));
    } else {
      return <LocationData>{};
    }
  }

  getUserLocation() {
    return this.http
      .get('https://tr1pp-ip-locator.herokuapp.com/location')
      .pipe(retry(10));
  }

  getCountryList() {
    return this.http.get('/assets/vendors/countries.json');
  }

  getCountriesFromContentFul() {
    return new Promise((resolve, reject) => {
      try {
        this.contentfulService
          .fetchEntriesForContentType({ sys: { id: 'countries' } })
          .then((data) => {
            resolve(this.contentfulService.transformData(data));
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  getCitiesFromContentFul(country) {
    const options = {
      content_type: 'cities',
      'fields.country.fields.slug': country,
      'fields.country.sys.contentType.sys.id': 'countries',
    };
    return new Promise((resolve, reject) => {
      try {
        this.contentfulService.fetchEntries(options).then((data) => {
          resolve(this.contentfulService.transformData(data));
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  getRegions() {
    return this.http.get(`${environment.baseURL}/regions`);
  }

  getIPLocation() {
    return this.http.get('http://tr1pp-ip-locator.herokuapp.com');
  }

  getLocationFare(payload) {
    return this.http.post(`${environment.baseURL}/fare`, payload);
  }
}
