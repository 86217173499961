import { Component, OnInit } from '@angular/core';
import { LocationService } from '../../services/location.service';
import { AppService } from '../../services/app.service';
import { GetMailComponent } from '../get-mail/get-mail.component';

@Component({
  selector: 'app-fare-estimate',
  templateUrl: './fare-estimate.component.html',
  styleUrls: ['./fare-estimate.component.scss']
})
export class FareEstimateComponent implements OnInit {
  pickUpLocation: { iso2: any; city: any; long: any; lat: any, address_components : any };
  onDropOffLocation: { iso2: any; city: any; long: any; lat: any };

  directionsService = new google.maps.DirectionsService();
  isGettingFares: any;
  results: any;
  details: any;
  fares: any;
  pickUpLocationString;
  onDropOffLocationString;
  errorMessage: any = '';

  constructor(private locationService: LocationService, private appService: AppService) {}

  searchConfig: google.maps.places.AutocompleteOptions = {
    componentRestrictions: {
      country: this.locationService.currentLocation.country.iso2
    }
  };

  ngOnInit() {}

  onPickUpSelect(event: any) {
    this.fares = false;
    this.results = null;
    this.errorMessage = '';
    this.pickUpLocation = {
      iso2: event['address_components'].find(address_component => {
        return address_component.types[0] === 'country';
      }).short_name,
      city: event['address_components'].find(address_component => {
        return address_component.types[0] === 'administrative_area_level_1';
      }).long_name,
      long: event['geometry'].location.lng(),
      lat: event['geometry'].location.lat(),
      address_components : event['address_components']
    };
  }

  onDropOffSelect(event: any) {
    this.fares = false;
    this.results = null;
    this.errorMessage = '';
    this.onDropOffLocation = {
      iso2: event['address_components'].find(address_component => {
        return address_component.types[0] === 'country';
      }).short_name,
      city: event['address_components'].find(address_component => {
        return address_component.types[0] === 'administrative_area_level_1';
      }).long_name,
      long: event['geometry'].location.lng(),
      lat: event['geometry'].location.lat()
    };
  }

  getFareEstimaste() {
    this.isGettingFares = true;
    Promise.all([this.getDistance('OPTIMISTIC'), this.getDistance('PESSIMISTIC'), this.getFares()])
      .then(results => {
        this.results = this.calculateFares(results[0], results[1], results[2]);
        this.isGettingFares = false;
        this.fares = true;
        this.errorMessage = '';
      })
      .catch(err => {
        this.isGettingFares = false;
        this.errorMessage = err.error.message || 'Something went wrong.';
      });
  }

  calculateFares(OPTIMISTICResult, PESSIMISTICResult, fareResult) {
    fareResult.car_categories.map(category => {
      const payload = {
        mininum_fare: Number(category.fare_variables.mininum_fare.value),
        base_fare: Number(category.fare_variables.base_fare.value),
        fare_per_minute: Number(category.fare_variables.fare_per_minute.value),
        fare_per_distance_unit: Number(category.fare_variables.fare_per_distance_unit.value),
        distance: OPTIMISTICResult.distance,
        duration: OPTIMISTICResult.duration_in_traffic,
        duration_in_traffic: PESSIMISTICResult.duration_in_traffic,
        distance_metric: fareResult.distance_metric,
        round: fareResult.currency.rounding
      };
      category['fares'] = this.calculatePrice(payload);
      return category;
    });
    fareResult.car_categories = fareResult.car_categories.sort((a, b) => a.fares[0] - b.fares[0]);
    return fareResult;
  }

  calculatePrice({
    mininum_fare,
    base_fare,
    fare_per_minute,
    fare_per_distance_unit,
    distance,
    duration,
    duration_in_traffic,
    distance_metric,
    round
  }) {
    let distance_unit, fare, fare_in_traffic, final_fare, final_fare_in_traffic, minute, minute_in_traffic;

    if (distance_metric === 'Kilometers') {
      distance_unit = distance / 1000;
    } else {
      distance_unit = distance / 1000 / 1.609;
    }

    minute = duration / 60;
    minute_in_traffic = duration_in_traffic / 60;

    fare = base_fare + fare_per_distance_unit * distance_unit + fare_per_minute * minute;
    fare = this.rounding(fare, round);
    fare_in_traffic = base_fare + fare_per_distance_unit * distance_unit + fare_per_minute * minute_in_traffic;
    fare_in_traffic = this.rounding(fare_in_traffic, round);

    final_fare = fare > mininum_fare ? fare : mininum_fare;
    final_fare_in_traffic = fare_in_traffic > mininum_fare ? fare_in_traffic : mininum_fare;

    return [
      final_fare < final_fare_in_traffic ? final_fare : final_fare_in_traffic,
      final_fare < final_fare_in_traffic ? final_fare_in_traffic : final_fare
    ];
  }

  getDistance(mode = 'PESSIMISTIC') {
    const origin = new google.maps.LatLng(this.pickUpLocation.lat, this.pickUpLocation.long);
    const destination = new google.maps.LatLng(this.onDropOffLocation.lat, this.onDropOffLocation.long);
    const travelMode = google.maps.TravelMode['DRIVING'];
    const drivingOptions: google.maps.DrivingOptions = {
      trafficModel: google.maps.TrafficModel[mode],
      departureTime: new Date()
    };
    const durationInTraffic = true;
    return new Promise((resolve, reject) => {
      this.directionsService.route({ origin, destination, travelMode, durationInTraffic, drivingOptions }, function(
        result: google.maps.DirectionsResult,
        status
      ) {
        try {
          const distance = result.routes[0].legs[0].distance.value;
          const duration = result.routes[0].legs[0].duration.value;
          const duration_in_traffic = result.routes[0].legs[0].duration_in_traffic.value;

          resolve({ distance, duration, duration_in_traffic });
        } catch (error) {
          reject(status);
        }
      });
    });
  }

  getFares() {
    return new Promise((resolve, reject) => {
      this.locationService.getLocationFare(this.pickUpLocation).subscribe(
        (res: any) => {
          if (res.data.car_categories.length === 0) {
            reject({ error: { message: 'Sorry, we are unable to provide a fare estimate for that trip.' } });
          } else {
            resolve(res.data);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  }

  showFareDetails(details) {
    this.details = details;
  }

  closeFareDetails() {
    this.details = null;
  }

  clearFareEstimate() {
    this.fares = false;
    this.results = null;
    this.pickUpLocationString = null;
    this.onDropOffLocationString = null;
    this.pickUpLocation.iso2 = null;
    this.onDropOffLocation.iso2 = null;
  }

  openOverlay() {
    this.appService.openOverlay(GetMailComponent);
  }

  rounding(value, round) {
    if (round == 0) {
      return value;
    }
    let multiplier = Math.round(value / round);
    return multiplier * round;
  }
}
