import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// pipes
import { EncodeEmailPipe } from './pipes/encode-email.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ToCamelCasePipe } from './pipes/to-camelcase.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { MomentModule } from 'ngx-moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  declarations: [
    EncodeEmailPipe,
    KeysPipe,
    SafePipe,
    TruncatePipe,
    ToCamelCasePipe,
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    MomentModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    NzSelectModule,
    NzCheckboxModule,
    NzFormModule,
    NzDatePickerModule,
    NzInputModule,
  ],
  exports: [
    EncodeEmailPipe,
    NgxSkeletonLoaderModule,
    MomentModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    KeysPipe,
    SafePipe,
    TruncatePipe,
    ToCamelCasePipe,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    NzSelectModule,
    NzCheckboxModule,
    NzFormModule,
    NzDatePickerModule,
    NzInputModule,
    
  ],
})
export class SharedModule {}
