import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationService {
  resourcesURI: IResourcesURI;

  constructor(private http: HttpClient) {
    this.resourcesURI = {
      business: environment.businessBaseURI,
      merchant: environment.merchantBaseURI,
    };
  }

  verifyEmail(resource, token) {
    const BASE_URI = this.resourcesURI[resource];
    return this.http.patch(`${BASE_URI}/${resource}/verify-email/${token}`, {});
  }

  resendEmailVerification(resource, payload) {
    const BASE_URI = this.resourcesURI[resource];

    return this.http.post(
      `${BASE_URI}/${resource}/resend-email-verification`,
      payload
    );
  }

  resendEmail(resource, payload) {
    const BASE_URI = this.resourcesURI[resource];
    return this.http.post(
      `${BASE_URI}/${resource}/resend-verification-link`,
      payload
    );
  }

}

interface IResourcesURI {
  business: string;
  merchant: string;
}
