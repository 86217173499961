import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

const contents = [
  {
    resource: 'business',
    title: 'Thank you for registering with Tr1pp',
    body:
      'One of our Sales Executives will get back to you within 1-5 working days',
    buttonText: 'Back to Tr1pp for Business',
    backLink: '/tr1pp-portal/business',
  },
  {
    resource: 'merchant',
    title: 'Thank you for registering as a Tr1pp merchant',
    body: 'Once your details are approved, an email will be sent to you',
    buttonText: 'Back to Tr1pp Merchant',
    backLink: '/tr1pp-portal/merchant',
  },
];

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you-message.component.html',
  styleUrls: ['./thank-you-message.component.scss'],
})
export class ThankYouMessageComponent implements OnInit {
  resourceContents: IContent[] = contents;
  content: IContent;

  constructor(private router: Router) {}

  ngOnInit() {
    const resource = this.router.url.split('/')[2];
    const { success } = history.state;

    if (!success) {
      // return this.router.navigate([resource]);
      return this.router.navigateByUrl(resource); // redirect lost guest to parent route 😁
    }

    this.content = this.resourceContents.find(
      (content) => content.resource === resource
    );
  }
}

interface IContent {
  resource: string;
  title: string;
  body: string;
  buttonText: string;
  backLink: string;
}
