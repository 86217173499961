import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { IUser } from '../shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  storeUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  get authUser(): IUser {
    if (isPlatformBrowser(this.platformId)) {
      const user = localStorage.getItem('user');
      return user ? JSON.parse(user) : null;
    }
  }

  get isAuth() {
    return !!this.authUser;
  }

  get authToken(): string {
    return this.authUser ? this.authUser.token : null;
  }

  get authType() {
    return this.authUser ? this.authUser.type : null;
  }

  isAuthType(type) {
    return this.isAuth && this.authUser.type === type;
  }

  logout() {
    localStorage.removeItem('user');
  }
}
