import { Injectable } from '@angular/core';

import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(private locationService: LocationService) {}

  getContent(data) {
    const {
      content: { items },
    } = data;

    let content = items.find((item) => this.isCountryContent(item));

    if (!content) {
      content = this.defaultContent(items);
    }

    return content.fields;
  }

  private isCountryContent(content, country = null) {
    // if country field is specified, then filter by country
    if (content.fields.country) {
      if (country) {
        return content.fields.country.fields.name === country;
      }

      return (
        content.fields.country.fields.name ===
        this.locationService.currentLocation.country.name
      );
    }

    return false;
  }

  private defaultContent(items) {
    const content = items.find((item) =>
      this.isCountryContent(item, 'United Kingdom')
    );

    return content || items[0];
  }
}
