export const LINKS = {
  default: [
    { linkUrl: '/tr1pp-portal/ride', linkText: 'Ride' },
    { linkUrl: '/tr1pp-portal/drive', linkText: 'Drive' },
    // { linkUrl: 'tr1pp-portal/card', linkText: 'Card' },
    // { linkUrl: 'tr1pp-portal/payment', linkText: 'Payments' },
    { linkUrl: '/tr1pp-portal/checkout', linkText: 'Checkout' },
    { linkUrl: '/tr1pp-portal/merchant', linkText: 'Merchant' },
    { linkUrl: '/tr1pp-portal/business', linkText: 'Business' },
    // { linkUrl: '/tr1pp-portal/mobile-money', linkText: 'Mobile Money' },
    { linkUrl: '/tr1pp-portal/money-transfer', linkText: 'International Money Transfer' },
  ],
};
