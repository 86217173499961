import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  overlayBus = new Subject();
  googleApiKey = environment.GOOGLE_API_KEY;
  baseUrl = environment.baseURL;

  constructor(private http: HttpClient) {}

  openOverlay(component = null, data = null) {
    this.overlayBus.next({ action: 'OPEN', component, data });
  }

  closeOverlay() {
    this.overlayBus.next({ action: 'CLOSE' });
  }

  locationAutoComplete(input) {
    const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&type=(cities)&key=${this.googleApiKey}`;
    return this.http.get(this.baseUrl.concat('/redirect'), {
      params: {
        mode: 'fetch',
        url,
      },
    });
  }

  getHelp() {}
}
