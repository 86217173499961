import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { WINDOW } from '@ng-toolkit/universal';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AppService } from '../../services/app.service';
import { RidersService } from '../../services/riders.service';
import { LocationService } from '../../services/location.service';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss'],
})
export class LocationSearchComponent implements OnInit {
  searchControl = this.fb.control(null);
  placeResults = [];
  countries = [];
  regions = [];
  showError: boolean;

  constructor(
    @Inject(WINDOW) private window: Window,
    private fb: FormBuilder,
    private ridersService: RidersService,
    private locationService: LocationService,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.getCountries();
    this.getRegions();
    this.searchControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          this.getPlaces(value);
        } else {
          this.placeResults = [];
        }
      });
  }

  getPlaces(input) {
    this.appService.locationAutoComplete(input).subscribe((res: any) => {
      this.placeResults = res.data.predictions;
    });
  }

  selectPlace(place) {
    const city = place.terms[place.terms.length - 2].value;
    const country_name = place.terms[place.terms.length - 1].value;
    let country = this.countries.find(
      (_country) => _country.name.toLowerCase() === country_name.toLowerCase()
    );
    if (!country) {
      country = this.countries.find((_country) =>
        _country.altSpellings.some(
          (spelling) => spelling.toLowerCase() === country_name.toLowerCase()
        )
      );
    }
    const country_match = this.regions.find(
      (region) => region.iso2 === country.alpha2Code
    );

    if (country_match) {
      this.showError = false;
      const data = {
        country: {
          name: country.nativeName,
          iso2: country.alpha2Code,
        },
        city: {
          name: city,
        },
      };
      this.locationService.setLocation(data);
      this.window.location.reload();
    } else {
      this.showError = true;
    }
  }

  clearSearch() {
    this.searchControl.patchValue(null);
    this.placeResults = [];
    this.showError = false;
  }

  getCountries() {
    this.ridersService.getCountryList().subscribe((res: any) => {
      this.countries = res;
    });
  }

  getRegions() {
    this.locationService.getRegions().subscribe((res: any) => {
      this.regions = res.data;
    });
  }
}
