import { Component, OnInit, Input } from '@angular/core';
import { CookieService } from '@ngx-toolkit/cookie';

import { AppService } from '../../services/app.service';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { SignInComponent } from '../sign-in/sign-in.component';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  @Input() links: any[];

  constructor(
    private cookieService: CookieService,
    private appService: AppService
  ) {}

  ngOnInit() {}

  get currentLocation() {
    return JSON.parse(this.cookieService.getItem('client_location'));
  }

  onSignUpClick() {
    this.appService.openOverlay(SignUpComponent);
  }

  onSignInClick() {
    this.appService.openOverlay(SignInComponent);
  }
}
