import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlidesService {
  private slide = new Subject();
  sharedSlide = this.slide.asObservable();

  constructor() {}

  nextSlide(slide) {
    this.slide.next(slide);
  }
}
