import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RidersService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  login(data) {
    return this.http.post(`${environment.baseURL}/login`, data);
  }

  forgotPassword(data) {
    return this.http.post(`${environment.baseURL}/forgotPassword`, data);
  }

  checkResetToken(token) {
    return this.http
      .post(`${environment.baseURL}/verify-reset-password-token`, {
        cipher: token,
      })
      .pipe(catchError(() => of({ success: false })));
  }

  resetPassword(payload) {
    return this.http.post(`${environment.baseURL}/resetPassword`, payload);
  }

  getCountryList() {
    return this.http.get('../../assets/vendors/countries.json');
  }

  getTrips() {
    return this.http.get(`${environment.baseURL}/riders/rides`, {
      headers: { authorization: this.authToken },
    });
  }

  get authToken() {
    return this.authService.authToken;
  }
}
