import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PlaceSearchDirective } from './place-search/place-search.directive';
import { ComponentHostDirective } from './component-host/component-host.directive';
import { HighlightMatchDirective } from './highlight-match/highlight-match.directive';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';

@NgModule({
  declarations: [
    PlaceSearchDirective,
    ComponentHostDirective,
    HighlightMatchDirective,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [
    PlaceSearchDirective,
    ComponentHostDirective,
    HighlightMatchDirective,
  ],
  providers: [CdkConnectedOverlay]
})
export class DirectivesModule {}
