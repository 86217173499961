import { Component, OnInit, Inject } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';
import { DOCUMENT } from '@angular/common';

import { RidersService } from '../../services/riders.service';
import { AuthService } from '../../services/auth.service';
import { HelpService } from '../../services/help.service';

@Component({
  selector: 'app-rider-trip-selector',
  templateUrl: './rider-trip-selector.component.html',
  styleUrls: ['./rider-trip-selector.component.scss'],
})
export class RiderTripSelectorComponent implements OnInit {
  trips = [];
  isFetchingTrips = false;
  currentTrip = null;
  map: google.maps.Map;
  isAuthenticated: boolean;

  constructor(
    private authService: AuthService,
    private riderService: RidersService,
    private helpService: HelpService,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.initMap();

    if (this.isAuthType('rider')) {
      this.getTrips();
    }

    if (this.isAuthType('driverPartner')) {
      this.getDriverTrips();
    }
  }

  isAuthType(type) {
    return this.authService.isAuthType(type);
  }

  getTrips() {
    this.isFetchingTrips = true;
    this.riderService.getTrips().subscribe((res: any) => {
      this.isFetchingTrips = false;
      this.setTrips(res.data);
    });
  }

  getDriverTrips() {
    this.isFetchingTrips = true;
    this.helpService.fetchDriverRides().subscribe((res: any) => {
      this.isFetchingTrips = false;
      this.setTrips(res.data.data);
    });
  }

  setTrips(data) {
    const tripStatus = ['CUSTOMER_CANCELLED', 'DRIVER_CANCELLED', 'RIDE_ENDED'];

    this.trips = data
      .filter((ride) => tripStatus.includes(ride.status))
      .sort((a, b) => {
        return <any>new Date(b.created_at) - <any>new Date(a.created_at);
      });

    const currentTrip = JSON.parse(localStorage.getItem('current_trip'));
    if (currentTrip) {
      this.currentTrip = currentTrip.ride_session_id;
    } else {
      const [trip] = this.trips;
      this.currentTrip = trip ? trip.ride_session_id : null;
    }
  }

  setCurrentTrip(trip_id) {
    const currentTrip = this.trips.find(
      (trip) => trip.ride_session_id === trip_id
    );
    localStorage.setItem('current_trip', JSON.stringify(currentTrip));
  }

  get currentTripDetails() {
    return this.trips.find((trip) => trip.ride_session_id === this.currentTrip);
  }

  initMap() {
    if (!this.map) {
      const myLatlng = new google.maps.LatLng(-34.397, 150.644);
      const mapOptions = {
        center: myLatlng,
        zoom: 10,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      };

      this.window.setTimeout(() => {
        if (this.document.getElementById('map')) {
          this.map = new google.maps.Map(
            this.document.getElementById('map'),
            mapOptions
          );
        }
      }, 3000);
    }
  }
}
