import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';
import { GetMailComponent } from '../get-mail/get-mail.component';

@Component({
  selector: 'app-pricing-table',
  templateUrl: './pricing-table.component.html',
  styleUrls: ['./pricing-table.component.scss'],
})
export class PricingTableComponent implements OnInit {
  @Input() premiumPlan: any;
  @Input() classicPlan: any;
  @Input() contents: any[];

  constructor(private appService: AppService) {}

  ngOnInit() {}

  openMailOverlay() {
    this.appService.openOverlay(GetMailComponent);
  }
}
