import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgZorroAntdModule } from 'ng-zorro-antd';

import { DirectivesModule } from '../directives/directives.module';

import { FAQSComponent } from './faqs/faqs.component';
import { FareEstimateComponent } from './fare-estimate/fare-estimate.component';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { FooterComponent } from './footer/footer.component';
import { GetMailComponent } from './get-mail/get-mail.component';
import { GetReadyMailComponent } from './get-ready-mail/get-ready-mail.component';
import { GlobalMapComponent } from './global-map/global-map.component';
import { HeaderComponent } from './header/header.component';
import { LocationSearchComponent } from './location-search/location-search.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { RiderTripSelectorComponent } from './rider-trip-selector/rider-trip-selector.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignupToDriveComponent } from './signup-to-drive/signup-to-drive.component';
import { YoutubeEmbedComponent } from './youtube-embed/youtube-embed.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ThankYouMessageComponent } from './thank-you-message/thank-you-message.component';
import { GetStartedActionFormComponent } from './get-started-action-form/get-started-action-form.component';
import { PricingTableComponent } from './pricing-table/pricing-table.component';
import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [
    FAQSComponent,
    FareEstimateComponent,
    FieldErrorDisplayComponent,
    FooterComponent,
    GetMailComponent,
    GetReadyMailComponent,
    GlobalMapComponent,
    HeaderComponent,
    LocationSearchComponent,
    MobileMenuComponent,
    RiderTripSelectorComponent,
    SignInComponent,
    SignUpComponent,
    SignupToDriveComponent,
    YoutubeEmbedComponent,
    VerifyEmailComponent,
    ThankYouMessageComponent,
    GetStartedActionFormComponent,
    PricingTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgZorroAntdModule,
    DirectivesModule,
  ],
  exports: [
    FAQSComponent,
    FareEstimateComponent,
    FieldErrorDisplayComponent,
    FooterComponent,
    GetMailComponent,
    GetReadyMailComponent,
    GlobalMapComponent,
    HeaderComponent,
    LocationSearchComponent,
    MobileMenuComponent,
    RiderTripSelectorComponent,
    SignInComponent,
    SignUpComponent,
    SignupToDriveComponent,
    YoutubeEmbedComponent,
    VerifyEmailComponent,
    ThankYouMessageComponent,
    GetStartedActionFormComponent,
    PricingTableComponent,
  ],
  entryComponents: [
    LocationSearchComponent,
    GetMailComponent,
    GetReadyMailComponent,
    GlobalMapComponent,
    MobileMenuComponent,
    SignInComponent,
    SignUpComponent,
    YoutubeEmbedComponent,
  ],
  providers: [CdkConnectedOverlay]
})
export class ComponentsModule {}
