// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'development',
  production: false,
  baseURL: 'https://tr1pp-main-backend-website.herokuapp.com',
  paymentURL: 'https://tr1pp-payment-services.herokuapp.com',
  paymentApiUrl: 'https://tr1pp-payment-services.herokuapp.com/api/v2',
  // driverPortalUrl: 'http://localhost:4203',
  driverPortalUrl: 'https://tr1pp-driver-portal.netlify.app/',
  businessPortalUrl: 'https://tr1pp-business-portal.netlify.app',
  // GOOGLE_API_KEY: 'AIzaSyCe_pUyDOYZJTLWILoEf-Zdoq-qJ1PPwQ0',
  GOOGLE_API_KEY: 'AIzaSyB1TP4HrFWd2V2vm3rqUw2PsYX9UEpVYV8',
  resetPasswordRedirectURI: 'http://localhost:4202/riders/reset-password',
  businessBaseURI: 'https://backend-business-portal.herokuapp.com/api/v1',
  merchantBaseURI: 'https://backend-merchant-service.herokuapp.com/api/v1',
  typeForm:'https://embed.typeform.com/next/embed.js',
  paymentUrl: "https://tr1pp-payment-services.herokuapp.com/api/v2",
  customerServiceUrl: 'https://backend-customer-service.herokuapp.com',
  driverURL: 'https://backend-driver-service.herokuapp.com/api/v1/driver',
  passengerURL: 'https://backend-customer-service.herokuapp.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
