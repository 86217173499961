import { Component, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';
import { GetMailComponent } from '../get-mail/get-mail.component';
import { GetReadyMailComponent } from '../get-ready-mail/get-ready-mail.component';
import { LocationService } from '../../services/location.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  constructor(private appService: AppService, private locationService: LocationService, private router: Router) { }

  ngOnInit() {
  }

  openMailOverlay() {
    this.appService.openOverlay(GetMailComponent);
  }

  openMerchant() {
    this.router.navigate(['/tr1pp-portal/merchant']);
  }

  openBusiness() {
    this.router.navigate(['/tr1pp-portal/business']);
  }

  openGetReadyMailOverlay() {
    const country = this.locationService.currentLocation.country.name;

    // if (country === 'Nigeria') {
      window.location.href = environment.driverPortalUrl;
    // } else {
    //   this.openMailOverlay();
    // }
  }

  openNew() {
    this.openMailOverlay();
  }

}
