export const toCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (substr, char) => char.toUpperCase());
};

export const toCapitalize = (str: string, separator = '_') => {
  return str
    .split(separator)
    .join(' ')
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
};

export const createFormData = (
  object: Object,
  form?: FormData,
  namespace?: string
): FormData => {
  const formData = form || new FormData();

  for (const property in object) {
    if (!object.hasOwnProperty(property) || !object[property]) {
      continue;
    }

    const formKey = namespace ? `${namespace}[${property}]` : property;

    if (object[property] instanceof Date) {
      formData.append(formKey, object[property].toISOString());
    } else if (
      typeof object[property] === 'object' &&
      !(object[property] instanceof File) &&
      !(object[property] instanceof FileList)
    ) {
      createFormData(object[property], formData, formKey);
    } else if (object[property] instanceof File) {
      const file: File = object[property];
      formData.append(`${namespace}`, file, file.name);
    } else {
      formData.append(formKey, object[property]);
    }
  }

  return formData;
};

export const createFileList = (files: File[]) => {
  const list = new DataTransfer();

  files.forEach((file) => {
    const newFile = new File([file], file.name);
    list.items.add(newFile);
  });

  return list.files;
};

export const parseCurrency = (value) => {
  return value
    .replace(/[^\d.-]/g, '') // remove non-numeric chars
    .replace(/^([^.]*\.)(.*)$/, function (a, b, c) {
      // remove extra dot
      return b + c.replace(/\./g, '');
    });
};
