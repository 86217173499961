import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appHighlightMatch]'
})
export class HighlightMatchDirective implements OnInit {

  @Input() query;
  @Input() content;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    const element = (<HTMLElement>this.el.nativeElement);
    element.innerHTML = this.content.replace(new RegExp(this.query, 'i'), match => {
      return '<span class="highlightText">' + match + '</span>';
    });
  }

}
