import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  baseUrl = environment.baseURL;
  authToken: string;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authToken = this.authService.authToken;
  }

  getIssueType(issue_type, help_category) {
    return this.http.get(`${this.baseUrl}/issues`, {
      params: { issue_type, help_category },
    });
  }

  createTicket(payload) {
    return this.http.post(`${this.baseUrl}/tickets`, payload, {
      headers: { authorization: `Bearer ${this.authToken}` },
    });
  }

  fetchDriverData() {
    return this.http.get(`${this.baseUrl}/driver`, {
      headers: { authorization: this.authToken },
    });
  }

  getCategories(): Observable<any> {
    return this.http.get(`${this.baseUrl}/help_categories`)
  }

  fetchDriverRides() {
    return this.http.get(`${this.baseUrl}/driver/rides`, {
      headers: { authorization: this.authToken },
    });
  }

  setDriverData(token) {
    this.authToken = token;

    return forkJoin([
      this.fetchDriverData(),
      this.fetchDriverRides().pipe(catchError(() => of([]))),
    ]);
  }

  authBusinessAccount(token, businessId) {
    this.authToken = token;

    return this.http.get(`${this.baseUrl}/affiliate/business/${businessId}`, {
      headers: { authorization: `Bearer ${token}` },
    });
  }
}
