import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { LocationSearchComponent } from '../location-search/location-search.component';
import { CookieService } from '@ngx-toolkit/cookie';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentFooterColor: any;
  copyrightYear: Date;
  showDownload: boolean = true;

  constructor(
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {
    this.changeFooterBehaviour();
  }

  ngOnInit() {
    this.copyrightYear = new Date();
    this.route.queryParams.subscribe(({download})=>{
      if(download == 'false'){
        this.showDownload = false
      }
    })
  }

  get geoLocation() {
    const clientLocation = JSON.parse(
      this.cookieService.getItem('client_location')
    );
    return clientLocation.city.name || clientLocation.country.name;
  }

  openLocationOverlay() {
    this.appService.openOverlay(LocationSearchComponent);
  }

  changeFooterBehaviour() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .forEach((e) => {
        this.currentFooterColor = this.route.root.firstChild.snapshot.data[
          'footerColor'
        ];
      });
  }

  goToAndroidStore() {
    window.open('https://play.google.com/store/apps/details?id=com.tr1pp.customer', '_blank');
  }

  goToIosStore() {
    window.open('https://apps.apple.com/us/app/tr1pp-superapp/id1550445121', '_blank');
  }

  get footerLogo() {
    let logo = 'assets/tr1pp-logo.svg';

    if (this.currentFooterColor === 'white') {
      logo = 'assets/tr1pp-black-logo.svg';
    }

    return logo;
  }
}
