import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';

import { environment } from '../../../environments/environment';
import { GetReadyMailComponent } from '../get-ready-mail/get-ready-mail.component';
import { AppService } from '../../services/app.service';
import { LocationService } from '../../services/location.service';
import { GetMailComponent } from '../get-mail/get-mail.component';

@Component({
  selector: 'app-signup-to-drive',
  templateUrl: './signup-to-drive.component.html',
  styleUrls: ['./signup-to-drive.component.scss'],
})
export class SignupToDriveComponent implements OnInit {
  driverPortalURI = environment.driverPortalUrl;

  constructor(
    private appService: AppService,
    private locationService: LocationService
  ) {}

  ngOnInit() {
    AOS.init({ duration: 1200, disable: 'mobile' });
  }

  // Uncomment the commented codes here to fix the link
  openGetReadyMailOverlay() {
    // const country = this.locationService.currentLocation.country.name;

    // if (country === 'Nigeria') {
    //   window.location.href = this.driverPortalURI;
    // } else {
      this.appService.openOverlay(GetMailComponent);
    // }
  }
}
