import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { RideComponent } from './pages/ride/ride.component';
import { CitiesComponent } from './pages/cities/cities.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { SafetyComponent } from './pages/safety/safety.component';
import { PayComponent } from './pages/pay/pay.component';
import { AutonomousTechnologyComponent } from './pages/autonomous-technology/autonomous-technology.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { LegalResolverService } from './resolvers/legal-resolver.service';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { ContentResolverService } from './resolvers/content-resolver.service';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { MobileMoneyComponent } from './pages/mobile-money/mobile-money.component';
import { MoneyTransferComponent } from './pages/money-transfer/money-transfer.component';
import { PaymentComponent } from './pages/payment/payment.component';

// meta information
import { metaData } from './core/meta-data';
import { HelpResolverService } from './resolvers/help-resolver.service';
import { CardComponent } from './pages/card/card.component';
import { PaymentRedirectDriverComponent } from './pages/redirects/payment-redirect-driver.component';
import { PaymentRedirectPassengerComponent } from './pages/redirects/payment-redirect-passenger.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { TypeformComponent } from './pages/typeform/typeform.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: '/tr1pp-portal', pathMatch: 'full' },
      {
        path: 'tr1pp-portal',
        loadChildren: () =>
          import('./tr1pp-portal/tr1pp-portal.module').then(
            (m) => m.Tr1ppPortalModule
          ),
      },
      {
        path: 'demo-store',
        loadChildren: () =>
          import('./demostore/demostore.module').then((m) => m.DemoStoreModule),
      },
     
      { path: '**', redirectTo: '/tr1pp-portal' },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100],
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
