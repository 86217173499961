import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { LocationService } from '../../services/location.service';
import { GetMailComponent } from '../get-mail/get-mail.component';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-get-started-action-form',
  templateUrl: './get-started-action-form.component.html',
  styleUrls: ['./get-started-action-form.component.scss'],
})
export class GetStartedActionFormComponent implements OnInit {
  selectedCountry = 'GBR';

  phoneNumber = new FormControl('', [
    Validators.required,
    Validators.minLength(7),
    Validators.maxLength(16),
  ]);

  @Input() theme = 'primary';

  countries = [];
  isLoading: boolean;

  constructor(
    private locationService: LocationService,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.getCountries();
  }

  getCountries() {
    this.isLoading = true;

    forkJoin([
      this.locationService.getCountryList(),
      this.locationService.getRegions(),
    ]).subscribe((res: any[]) => {
      const [countryList, regions] = res;

      const iso2List = regions.data.map((item) => item.iso2);
      this.countries = countryList.filter((item) => {
        return (
          item.callingCodes[0].length > 0 && iso2List.includes(item.alpha2Code)
        );
      });

      this.isLoading = false;
    });
  }

  getCountry(alpha3Code: string) {
    return this.countries.find((item) => item.alpha3Code === alpha3Code);
  }

  keyPress(event) {
    const pattern = /^([0-9])$/;
    const isValid = pattern.test(event.key);

    return isValid && this.phoneNumber.value.length <= 15;
  }

  get countryCode() {
    if (!this.selectedCountry) {
      return null;
    }

    const country = this.getCountry(this.selectedCountry);
    return `+${country.callingCodes[0]}`;
  }

  openMailOverlay() {
    const phoneNumber = this.phoneNumber.value.replace(/^0+/, '');
    const data = {
      phoneNumber: this.countryCode.concat('-', phoneNumber),
    };

    this.appService.openOverlay(GetMailComponent, data);
    this.phoneNumber.reset(); // reset phone number
  }
}
