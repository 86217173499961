import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd';

import { EmailVerificationService } from '../../services/email-verification.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  email: string;
  invalidVerificationLink: boolean;
  model = { email: '' };
  isLoading = false;
  verifyingEmail = false;
  modalVisible = false;
  resource: string;

  encode: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emailVerificationService: EmailVerificationService,
    private notification: NzMessageService
  ) {}

  ngOnInit() {
    this.resource = this.router.url.split('/')[2]; // business or merchant
    this.model.email = this.email = history.state.email;
    this.route.queryParamMap.subscribe((params) => {
      const token = params.get('cipher') || '';
      this.encode = params.get('enc');

      if (token) {
        this.verifyEmail(token);
      } else {
        this.invalidVerificationLink = true;
      }
    });
  }

  verifyEmail(token) {
    const resource = this.resource;
    this.verifyingEmail = true;
    this.emailVerificationService.verifyEmail(resource, token).subscribe(
      (res) => {
        this.verifyingEmail = false;
        this.router.navigateByUrl(`/tr1pp-portal/${resource}/thank-you`, {
          state: { success: true },
        });
      },
      (error) => {
        this.verifyingEmail = false;
        this.invalidVerificationLink = true;
      }
    );
  }

  resendEmailVerification(email = '') {
    if (email === '') {
      email = atob(this.encode);
    }

    const getRedirectUrl = (type) => `${location.origin}/${type}/verify-email`;
    // const payload = {
    //   email,
    //   redirectUrl: getRedirectUrl(this.resource),
    // };
    const payload = {
      email,
    };
    this.isLoading = true;
    this.emailVerificationService.resendEmail(this.resource, payload).subscribe((res: any) => {
      // this.emailVerificationService.resendEmailVerification(this.resource, payload).subscribe((res: any) => {
        this.email = email;
        this.isLoading = false;
        this.modalVisible = false;
        this.notification.success(res.message);
      },
      ({ error }) => {
        this.isLoading = false;
        this.modalVisible = false;

        const message =
          error.message ||
          'Oops! Something went wrong, please try again later.';
        this.notification.warning(message);
      }
    );
  }

  showModal() {
    this.modalVisible = true;
  }

  handleCancel() {
    this.modalVisible = false;
  }
}
