import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from '@angular/platform-browser';

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(
    value: any,
    type: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    const sanitizerHandler = {
      html: this.sanitizer.bypassSecurityTrustHtml,
      style: this.sanitizer.bypassSecurityTrustStyle,
      script: this.sanitizer.bypassSecurityTrustScript,
      url: this.sanitizer.bypassSecurityTrustUrl,
      resourceUrl: this.sanitizer.bypassSecurityTrustResourceUrl,
    };

    if (!sanitizerHandler.hasOwnProperty(type)) {
      throw new Error(`Invalid safe type specified: ${type}`);
    }

    // cleaner way to run and avoid if/else/switch 😉
    return sanitizerHandler[type](value); // run sanitizer by type
  }
}
