import { Injectable } from '@angular/core';
import { ContentfulService } from './contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private contentfulService: ContentfulService, private locationService: LocationService) { }

  getBlogPosts(id = 'posts', limit = null) {


    return Promise.all([this.getGlobalPosts(), this.getCountryPosts(), this.getCityPosts()])
      .then((postArray) => {
        let posts = postArray.reduce((acc, val) => acc.concat(val), []);
        return posts.sort((prev: { created_at: string | number | Date; }, next: { created_at: string | number | Date; }) => {
          return <any>new Date(next.created_at) - <any>new Date(prev.created_at);
        });
      })

  }


  getGlobalPosts(category = null, expect = null, limit = null) {
    let globalOptions = {
      content_type: 'posts',
      'fields.location.fields.name': `Global`,
      'fields.location.sys.contentType.sys.id': 'continent',
    }
    if (category) {
      globalOptions['fields.category.fields.slug'] = category;
      globalOptions['fields.category.sys.contentType.sys.id'] = 'categories'
    }

    if (expect) {
      globalOptions['fields.slug[ne]'] = expect.slug;
    }

    if (limit) {
      globalOptions['limit'] = limit;
    }

    return this.contentfulService.fetchEntries(globalOptions)
      .then((data) => {
        return this.transformToPosts(data)
      })
  }

  getCountryPosts(category = null, expect = null, limit = null) {
    let countryOptions = {
      content_type: 'posts',
      'fields.location.fields.name': `${this.locationService.currentLocation.country.name}`,
      'fields.location.sys.contentType.sys.id': 'countries',
    }
    if (category) {
      countryOptions['fields.category.fields.slug'] = category;
      countryOptions['fields.category.sys.contentType.sys.id'] = 'categories'
    }

    if (expect) {
      countryOptions['fields.slug[ne]'] = expect.slug;
    }

    if (limit) {
      countryOptions['limit'] = limit;
    }
    return this.contentfulService.fetchEntries(countryOptions)
      .then((data) => {
        return this.transformToPosts(data)
      })


  }

  getCityPosts(category = null, expect = null, limit = null) {
    let cityOptions = {
      content_type: 'posts',
      'fields.location.fields.name': `${this.locationService.currentLocation.city.name}`,
      'fields.location.sys.contentType.sys.id': 'cities',
    }
    if (category) {
      cityOptions['fields.category.fields.slug'] = category;
      cityOptions['fields.category.sys.contentType.sys.id'] = 'categories'
    }

    if (expect) {
      cityOptions['fields.slug[ne]'] = expect.slug;
    }

    if (limit) {
      cityOptions['limit'] = limit;
    }

    return this.contentfulService.fetchEntries(cityOptions)
      .then((data) => {
        return this.transformToPosts(data)
      })
  }


  getBlogPost(slug, id = 'posts') {
    return this.contentfulService.fetchEntryBySlug({ sys: { id } }, slug)
      .then(post => this.transformToPost(post));
  }

  getRelatedPosts(post) {
    return Promise.all([this.getGlobalPosts(post.category_slug, post, 3), this.getCountryPosts(post.category_slug, post, 3), this.getCityPosts(post.category_slug, post, 3)])
      .then((postArray) => {
        let posts = postArray.reduce((acc, val) => acc.concat(val), []);
        return posts.sort((prev: { created_at: string | number | Date; }, next: { created_at: string | number | Date; }) => {
          return <any>new Date(next.created_at) - <any>new Date(prev.created_at);
        })
          .slice(0, 1);
      })
  }

  getCategories() {
    const options = {
      content_type: 'categories',
    };
    return this.contentfulService.fetchEntries(options);
  }

  getCategoryPosts(category) {
    const category_options = {
      'content_type': 'categories',
      'fields.slug': category
    };

    const posts = Promise.all([this.getGlobalPosts(category), this.getCountryPosts(category), this.getCityPosts(category)])
      .then((postArray) => {
        let posts = postArray.reduce((acc, val) => acc.concat(val), []);
        return posts.sort((prev: { created_at: string | number | Date; }, next: { created_at: string | number | Date; }) => {
          return <any>new Date(next.created_at) - <any>new Date(prev.created_at);
        });
      });

    const categoryDetails = this.contentfulService.fetchEntries(category_options)
      .then((data) => {
        return data.items[0].fields;
      });

    return Promise.all([categoryDetails, posts])
      .then(
        data => {
          return {
            category: data[0],
            posts: data[1]
          };
        }
      );

  }


  transformToPost(post) {
    return post['display'] = {
      title: post.fields.title,
      slug: post.fields.slug,
      description: post.fields.description,
      category: post.fields.category.fields.name,
      category_slug: post.fields.category.fields.slug,
      cover_image: post.fields.coverImage.fields.file.url,
      created_at: post.sys.createdAt,
      author: post.fields.author,
      featured: post.fields.featured,
      post: documentToHtmlString(post.fields.post, <Partial<any>>this.options),
      location: post.fields.location.fields.name
    };
  }

  transformToPosts(data) {
    return data.items.map(post => {
      return post['display'] = {
        title: post.fields.title,
        slug: post.fields.slug,
        description: post.fields.description,
        category: post.fields.category.fields.name,
        category_slug: post.fields.category.fields.slug,
        cover_image: post.fields.coverImage.fields.file.url,
        created_at: post.sys.createdAt,
        featured: post.fields.featured,
        location: post.fields.location.fields.name
      };
    });
  }

  get options() {
    return {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields } } }) =>
          `<img
      style="width: 100%;
      max-height: 500px;
      object-fit: cover;
      object-position: center;
      margin-bottom: 50px;
      margin-top: 50px;"
      src="${fields.file.url}" alt="${fields.description}"/>`
      }
    };
  }

}
