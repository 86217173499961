import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { CookieService } from '@ngx-toolkit/cookie';
import { HttpClient } from '@angular/common/http';
import { catchError, mergeMap, retry } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocationResolverService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private cookieService: CookieService,
    private http: HttpClient
  ) {}

  load() {
    return new Promise((resolve, reject) => {
      if (isPlatformBrowser(this.platformId)) {
        const url = 'https://tr1pp-ip-locator.herokuapp.com/location';
        if (this.cookieService.hasItem('client_location')) {
          resolve(true);
        } else {
          return this.http
            .get(url)
            .pipe(retry(10))
            .toPromise()
            .then((data: any) => {
              this.setLocation(data);
              resolve(true);
            })
            .catch(error => {
              // Set UK as default when iplocator fails
              const data = {
                country: {
                  name: 'United Kingdom',
                  iso2: 'BG'
                },
                city: {
                  name: 'London'
                }
              };
              this.setLocation(data);
              resolve(true);
            });
        }
      } else {
        resolve(true);
      }
    });
  }

  setLocation(data) {
    const location_data = {
      country: {
        name: data.country_name,
        iso2: data.country_code
      },
      city: {
        name: data.city
      }
    };
    this.cookieService.setItem('client_location', JSON.stringify(location_data));
  }
}
