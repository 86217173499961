import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { environment } from '../environments/environment';
import { SEOService } from './services/seo.service';

declare let ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private renderer2: Renderer2,
    private http: HttpClient,
    private seoService: SEOService
  ) {}

  onActivate(e) {
    this.window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.seoService.init();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });

    if (!this.document.getElementById('googlemaps')) {
      const script = this.renderer2.createElement('script');
      script.id = 'googlemaps';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_API_KEY}&libraries=places`;
      this.renderer2.appendChild(this.document.head, script);
    }
  }
}
