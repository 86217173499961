import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeEmail'
})
export class EncodeEmailPipe implements PipeTransform {
  transform(email: string): any {
    if (!email) {
      return email;
    }

    const [usrName, domain] = email.split('@');
    const username = usrName.split('');
    const middle = Math.floor(username.length / 2);
    // replace first half with astericks
    const firstHalf = username.slice(0, middle).map(() => '*');
    const secondHalf = username.slice(middle, username.length);

    return [...firstHalf, ...secondHalf, '@', domain].join('');
  }
}
