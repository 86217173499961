import { Component, OnInit } from '@angular/core';

import { AppService } from '../../services/app.service';
import { GetMailComponent } from '../get-mail/get-mail.component';
import { environment } from '../../../environments/environment';
import { GetReadyMailComponent } from '../get-ready-mail/get-ready-mail.component';
import { LocationService } from '../../services/location.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  businessPortal = environment.businessPortalUrl;

  constructor(private appService: AppService, private locationService: LocationService) {}

  ngOnInit() {}

  openMailOverlay() {
    this.appService.openOverlay(GetMailComponent);
  }

  openGetReadyMailOverlay() {
    // const country = this.locationService.currentLocation.country.name;

    // if (country === 'Nigeria') {
      window.location.href = environment.driverPortalUrl;
    // } else {
    //   this.openMailOverlay();
    // }
  }

  openNew() {
    this.openMailOverlay();
  }
  
}
